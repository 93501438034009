/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PublicApiBaseUrl from '../../api/PublicApiService';
import { IWebsiteContentMappingsModel } from '../../models/WebsiteManagement/WebsiteContentMappingsModel';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

type ComponentProperties = {};

const websiteContentMappingTypes = [
  { name: 'Trek', code: 'trek' },
  { name: 'Post', code: 'post' },
  { name: 'Family Trek', code: 'family_trek' },
  { name: 'Articles Landing', code: 'articles_landing_type' },
  { name: 'Document Trek', code: 'document_trek_type' },
  { name: 'Pillar', code: 'pillar' },
];

export const WebsiteContentMapping: React.FC<ComponentProperties> = (props) => {
  const { register, handleSubmit, reset, setValue, control, getValues, errors, formState } = useForm<{
    contentMappingUid: string;
    contentMappingType: string;
  }>({});

  const defaultWebsiteContentMappings = {
    contentMappingUid: '',
    contentMappingType: '',
  };

  const [websiteContentMappings, setWebsiteContentMappings] = useState<IWebsiteContentMappingsModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const toast = useRef(null);
  const publicApiService = PublicApiBaseUrl;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    publicApiService
      .getAllWebsiteContentMappings()
      .then((response) => {
        setWebsiteContentMappings(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        const tst: any = toast?.current;
        tst.show({
          severity: 'error',
          summary: 'Error fetching website content mapping, Please try again.',
          detail: error.message,
        });
      });
  };

  const actionTemplate = (data: any) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <div className="p-px-2">
            <Button
              label=""
              icon="pi pi-trash"
              className="p-button-danger p-icon-btn-custom"
              onClick={(e) => {
                onDelete(data.uid);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const onDelete = (uid: string) => {
    confirmDialog({
      icon: 'pi pi-exclamation-triangle',
      message: ` Are you sure you want to delete '${uid}' mapping?`,
      accept: () => {
        setLoading(true);
        publicApiService
          .deleteWebsiteContentMapping(uid)
          .then((response) => {
            setLoading(false);
            if (response === 202) {
              const tst: any = toast?.current;
              tst.show({ severity: 'success', summary: 'Website content mapping deleted successfully.', detail: '' });
              fetchData();
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
            const tst: any = toast?.current;
            tst.show({
              severity: 'error',
              summary: 'Error deleting website content mapping, Please try again.',
              detail: error.message,
            });
          });
      },
    });
  };

  const onSubmit = (data: any) => {
    if (data.contentMappingUid && data.contentMappingType) {
      setLoading(true);
      publicApiService
        .saveWebsiteContentMapping(data.contentMappingUid, data.contentMappingType)
        .then((response) => {
          setLoading(false);
          if (response === 204) {
            reset(defaultWebsiteContentMappings);
            const tst: any = toast?.current;
            tst.show({ severity: 'success', summary: 'Website content mapping added successfully.', detail: '' });
            fetchData();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          const tst: any = toast?.current;
          tst.show({
            severity: 'error',
            summary: 'Error adding website content mapping, Please try again.',
            detail: error.message,
          });
        });
    }
  };

  const gridTableHeader = (
    <div className="table-header">
      All Website Content Mapping
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalSearch}
          onChange={(e: any) => setGlobalSearch(e.target.value)}
          placeholder="Global Search"
        />
      </span>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <div className="card">
          <h3>Create new website content mapping:</h3>
          <div className="p-grid">
            <div className="p-col-12 p-md-12">
              <div className="p-mb-2">
                <label htmlFor="name">Website Content Mapping Uid</label>
              </div>
              <Controller
                name="contentMappingUid"
                control={control}
                render={({ onChange, value }) => (
                  <InputText
                    style={{ width: 'inherit' }}
                    id="contentMappingUid"
                    value={value}
                    placeholder="Enter website content mapping uid"
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="p-col-12 p-md-12">
              <div className="p-mb-2">
                <label htmlFor="name">Website Content Mapping Type</label>
              </div>
              <Controller
                name="contentMappingType"
                control={control}
                render={({ onChange, value }) => (
                  <Dropdown
                    optionLabel="name"
                    optionValue="code"
                    value={value}
                    options={websiteContentMappingTypes}
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                    placeholder="Select a website content mapping type"
                    filter
                    showClear
                    filterBy="name"
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex" style={{ paddingBottom: '10px' }}>
            <div className="flex-grow-1"></div>
            <div>
              <Button
                type="submit"
                label="Submit"
                icon="pi pi-filter"
                className="p-mx-2 p-button-lg p-button-secondary"
                disabled={formState.isSubmitting || !formState.isValid}
              >
                {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <div className="p-grid table-demo">
        <div className="card">
          <DataTable
            value={websiteContentMappings}
            paginator
            className="p-datatable-customers"
            rows={5}
            dataKey="id"
            rowHover
            emptyMessage="No website content mappings found."
            loading={loading}
            header={gridTableHeader}
            globalFilter={globalSearch}
          >
            <Column field="uid" header="Uid"></Column>
            <Column field="type" header="Type"></Column>
            <Column
              headerStyle={{ width: '120px', textAlign: 'center' }}
              bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
              body={actionTemplate}
              header="Action"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};
export default WebsiteContentMapping;
